import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_head = _resolveComponent("l-head")!
  const _component_l_body = _resolveComponent("l-body")!
  const _component_l_foot = _resolveComponent("l-foot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_l_head),
    _createVNode(_component_l_body, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }),
    _createVNode(_component_l_foot)
  ]))
}