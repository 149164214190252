<!--
  - Copyright (C) 2022 Do inc. - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  - Written by Emanuele (ebalo) Balsamo <emanuele.balsamo@do-inc.co>
  -->

<template>
	<main>
		<slot></slot>
	</main>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
	name: "l-body"
})
</script>

<style scoped>

</style>
