
import {urls} from "@/composition/header";
import {routes} from "@/router"
import {defineComponent, watch} from "vue";
import {RouteMeta} from "vue-router";
import {capitalize} from "@/composition/strings";
import {Address} from "@/composition/address";
import {Provider} from "@/composition/provider";
import Toaster from "@/composition/toaster";

interface navigationUrl {
	label: string,
	path: string,
	active: boolean,
	meta?: RouteMeta
}

export default defineComponent({
	name: "head2",
	data: () => ({
		scroll: {
			trigger: 1,
			animated: false,
		},
		mobile: {
			open: false,
			animate: false,
			content: false,
		},
		local_urls: [] as navigationUrl[],
		isConnected: Address.init().isConnected,
		connectedAs: Address.init().connectedAs,
	}),
	setup() {
		return {
			...urls(),
		}
	},
	methods: {
		openMobileNav() {
			this.mobile.animate = true
			setTimeout(() => {
				this.mobile.open = true
				this.mobile.content = true
			}, 1200)
		},
		closeMobileNav() {
			this.mobile.content = false
			this.mobile.animate = false
			setTimeout(() => {
				this.mobile.open = false
			}, 1200)
		},
		onSignInPress() {
			this.closeMobileNav();
			new Toaster({
				title: `Coming Soon feature`,
				message: ``,
				type: "error"
			})
		}
	},
	computed: {
		connectionButton(): any {
			return {
				classes: !this.isConnected ? "cursor-pointer" : "",
				method: !this.isConnected ? () => {
					Provider.init().connectWallet()
				} : () => false
			}
		},
		navAnimationClasses() {
			return {
				wrapper: {
					"fixed z-50 h-20 bg-white backdrop-filter backdrop-blur bg-opacity-50 flex items-center": this.scroll.animated,
				},
				nav: {
					"my-4": this.scroll.animated,
					"my-6": !this.scroll.animated,
				},
				content: {
					"mt-64": this.scroll.animated,
					"mt-16": !this.scroll.animated,
				},
				logo: {
					"md:w-1/3 w-full": this.scroll.animated,
					"md:w-2/3 w-full": !this.scroll.animated,
				},
				mobile: {
					wrapper: {
						"z-[-1] translate-x-full": !this.mobile.open && !this.mobile.animate,
						"z-20": this.mobile.animate || this.mobile.open,
						"bg-black": this.mobile.open && this.mobile.animate
					},
					lines: {
						common: {
							"translate-x-full": !this.mobile.animate
						},
						items: [
							"delay-[150ms]",
							"delay-[300ms]",
							"delay-[450ms]",
							"delay-[600ms]",
							"delay-[750ms]",
							"delay-[900ms]",
						]
					},
					content: {
						"opacity-0": !this.mobile.content,
						"opacity-100": this.mobile.content
					}
				}
			}
		},
		urlsTargets() {
			return this.urls.map((v: any) => {
				let is_external: boolean = v.external
				return {
					target: is_external ? "_blank" : "",
					rel: is_external ? "noopener" : ""
				}
			})
		},
	},
	async created() {
		//fixed trigger
		let _this = this
		document.addEventListener('scroll', function () {
			if (window.scrollY >= _this.scroll.trigger && !_this.scroll.animated) {
				_this.scroll.animated = true
			} else if (window.scrollY < _this.scroll.trigger && _this.scroll.animated) {
				_this.scroll.animated = false
			}
		});

		//init
		Address.init().watchAddress((v: string): void => {
			this.connectedAs = !!v ? v : false
			this.isConnected = !!v
		})

		const refreshRoutes = () => {
			// reset the urls
			this.local_urls = []

			routes.forEach((v: any) => {
				// push the navigation url structure to the array
				this.local_urls.push({
					// if the url has a label tag than use the capitalized label, otherwise use the capitalized name of
					// the route
					label: v.meta && v.meta.label ? capitalize(v.meta.label) : capitalize(v.name as string),
					path: v.path,
					active: this.$route.name === v.name || this.$route.path === v.path,
					meta: v.meta
				})
			})
		}
		// trigger a first call of the method in order to init the urls array
		refreshRoutes()

		// init a deep object watcher that triggers the route refresh each time the route changes
		watch(
			() => this.$route,
			refreshRoutes,
			{
				deep: true,
				immediate: true
			}
		)
	}
})
