import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["p-2 rounded-md mr-2 select-none", _ctx.shimmer])
  }, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.text !== undefined)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.text), 1)
              ], 64))
            : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
        ], 64))
  ], 2))
}