
import {defineComponent} from "vue";
import Faq from "@/components/Faq.vue";
import {Stackable} from "@/composition/staking/types";
import {Staking} from "@/composition/staking";
import Shimmer from "@/components/shimmer.vue";
import {home} from "@/composition/home";

export default defineComponent({
	name: "index",
	components: {Faq, Shimmer},
	data: () => ({
		staking_ready: false,
		stackable: [] as Stackable[],
	}),
	methods: {},
	computed: {
		bestAPY() {
			return this.stackable.length > 0 ? this.stackable.reduce((previousValue, currentValue) => {
				return +previousValue.apy > +currentValue.apy ? previousValue : currentValue
			}).apy : "0.00"
		},
	},
	setup() {
		return {
			...home(),
		}
	},
	created() {
		Staking.init().onStackingReady.subscribe(() => {
			this.staking_ready = true
			this.stackable = Staking.init().stackable
		})
	}
})
