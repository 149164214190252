
import {defineComponent, PropType} from "vue";

export default defineComponent({
	name: "shimmer",
	props: {
		loading: {
			type: Boolean as PropType<boolean>,
			default: true
		},
		text: {
			type: String as PropType<string>,
		}
	},
	data: () => ({
		placeholder: "O".repeat(10)
	}),
	computed: {
		shimmer() {
			return this.loading ? "shimmer" : ""
		}
	}
})
